@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');

#template-card-4 * {
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
}

#template-card-4 .overall-headline {
  margin-bottom: 16px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.85);
}

#template-card-4.template-card {
  max-width: 832px;
  container-type: inline-size;
}

#template-card-4 .template-button {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px auto;
  gap: 10px;
  width: 144px;
  height: 40px;
  background: #1890ff;
  border: 1px solid #1890ff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  flex: flex;
  text-align: center;
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  transition: filter 0.3s;
}

#template-card-4 .template-button:hover {
  filter: brightness(1.1);
}

#template-card-4 .template-card-list {
  display: grid;
}

#template-card-4 div.divider {
  border-top: 1px solid #f0f0f0;
  width: 100%;
  margin: 24px 0;
}

.template-card-items {
  display: grid;
  gap: 24px;
}
#template-card-4 .template-card-item {
  display: grid;
  grid-template-columns: 204px 3fr 1fr;
  gap: 24px;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02),
    0 2px 4px 0 rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  padding: 24px;
}

#template-card-4 .template-card-item__checklist {
  flex: 1;
  gap: 4px;
  display: grid;
}

#template-card-4 .template-card-item__checklist-item {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  gap: 8px;
  align-items: center;
  color: #000001;
}

#template-card-4 .template-card-item__action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

#template-card-4 .template-card-item__image {
  object-fit: contain;
  width: 204px;
  height: 104px;
}
#template-card-4 .rating {
  display: flex;
  justify-items: space-between;
  width: 100%;
  justify-content: center;
  gap: 12px;
  margin-bottom: 4px;
  align-items: flex-end;
}

#template-card-4 .rating__stars {
  /* font-size: 18px;  */
  font-size: 12px;
  line-height: 20px;
}

#template-card-4 .rating__number {
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
}

#template-card-4 .belowTextButton {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.ribbon-container {
  box-sizing: border-box;
  margin: 0;
  padding: 0 8px;
  color: #1677ff;
  font-size: 16px;
  line-height: 22px;
  list-style: none;
  position: absolute;
  top: -12px;
  height: 28px;
  white-space: nowrap;
  background-color: #1677ff;
  border-radius: 4px 4px 4px 0;
  inset-inline-start: -8px;
}

.ribbon {
  margin: 0;
  padding: 0 8px 0 0;
  font-size: 14px;
  line-height: 28px;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  position: absolute;
  height: 28px;
  white-space: nowrap;
  border-radius: 4px;
}

.ribbon-corner {
  position: absolute;
  top: 100%;
  width: 8px;
  height: 8px;
  color: currentcolor;
  border: 4px solid;
  transform: scaleY(0.75);
  transform-origin: top;
  filter: brightness(75%);

  inset-inline-start: 0;
  border-block-end-color: transparent;
  border-inline-start-color: transparent;
}

.ribbon-text {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

@container (max-width: 528px) {
  #template-card-4 .template-card {
    max-width: 528px;
  }

  #template-card-4 .template-card-item {
    display: flex;
    flex-direction: column;
  }

  #template-card-4 .template-card-item__action {
    display: flex;
  }

  #template-card-4 .template-button {
    width: 100%;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    height: 40px;
  }

  .ribbon-text {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
  }
}
