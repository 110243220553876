@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');

#template-card-3 * {
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
}

#template-card-3 .overall-headline {
  margin-bottom: 16px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.85);
}

#template-card-3.template-card {
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-width: 832px;
  height: fit-content;
  font-family: 'Open Sans', sans-serif;
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  /*width: 100%;*/
  container-type: inline-size;
}

#template-card-3 .headline {
  color: #1890ff;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

#template-card-3 .productName {
  margin-bottom: 4px;
}

#template-card-3 .productName span {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.85);
  text-decoration: underline;
}

#template-card-3 .productName span:hover {
  filter: opacity(80%);
  text-decoration: none;
  cursor: pointer;
}

#template-card-3 .description {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

#template-card-3 .button {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px auto;
  gap: 10px;
  width: 144px;
  height: 40px;
  background: #1890ff;
  border: 1px solid #1890ff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  flex: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  transition: filter 0.3s;
}

#template-card-3 .button:hover {
  filter: brightness(1.1);
}

#template-card-3 .template-card-list {
  display: grid;
}

div.divider {
  border-top: 1px solid #f0f0f0;
  width: 100%;
  margin: 24px 0;
}

.template-card-item {
  display: grid;
  grid-template-columns: 1fr 4fr 0.5fr;
  gap: 24px;
}

#template-card-3 .template-card-item__info {
  flex: 1;
}

#template-card-3 .template-card-item__action {
  display: flex;
  align-items: center;
}

#template-card-3 .template-card-item__image {
  object-fit: cover;
  width: 104px;
  height: 104px;
}

@container (max-width: 528px) {
  #template-card-3 .template-card {
    max-width: 528px;
  }

  #template-card-3 .template-card-item {
    display: grid;
    grid-template-columns: min-content auto;
  }

  #template-card-3 .template-card-item__action {
    display: flex;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 2;
  }

  #template-card-3 .template-card-item__action .button {
    width: 100%;
    flex: 1;
  }
}
