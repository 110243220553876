@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');

#template-card-2 * {
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
}

#template-card-2 div.divider {
  border-top: 1px solid #f0f0f0;
  width: 100%;
  margin: 24px 0;
}

#template-card-2 p,
#template-card-2 span,
#template-card-2 div {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}

#template-card-2.template-card {
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-width: 832px;
  height: fit-content;
  font-family: 'Open Sans', sans-serif;
  /* Neutral/1 */
  background: #ffffff;
  /* drop-shadow/0.15 */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  container-type: inline-size;
}

#template-card-2 .headline {
  margin-bottom: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #368dff;
}
#template-card-2 .brand-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 8px;
}

#template-card-2 .product-name {
  margin-bottom: 4px;
}

#template-card-2 .product-name span {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-decoration: underline;
  transition: filter 0.3ms;
}

#template-card-2 .product-name span:hover {
  filter: opacity(80%);
  text-decoration: none;
  cursor: pointer;
}

#template-card-2 .promo {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 4px;
  color: #1890ff;
}

#template-card-2 .card-content {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 24px;
}

#template-card-2 .card-content__aside {
  display: flex;
  flex-direction: column;
}

#template-card-2 .card-content__image-wrapper {
  flex: 1;
}

#template-card-2 .card-content__image {
  width: 100%;
  object-fit: cover;
}

#template-card-2 .paragraph {
  margin: 0;
}

#template-card-2 .template-button {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 10px;
  height: 40px;
  background: #1890ff;
  border: 1px solid #1890ff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  flex: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
  cursor: pointer;
  transition: filter 0.3s;
}

#template-card-2 .template-button:hover {
  filter: brightness(1.1);
}

#template-card-2 .prons-cons-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

#template-card-2 .prons-cons__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 8px;
}

#template-card-2 .prons-cons__list {
  display: grid;
  gap: 8px;
}

#template-card-2 .prons-cons__item {
  align-items: center;
  display: grid;
  grid-template-columns: 9px auto;
  gap: 8px;
}

#template-card-2 .prons-cons__item__text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

@container (max-width: 528px) {
  #template-card-2 .template-card {
    max-width: 528px;
  }

  #template-card-2 .card-content__image-wrapper {
    flex: 1;
  }

  #template-card-2 .card-content__image {
    width: auto;
    height: auto;
    object-fit: contain;
    max-width: 100%;
  }

  #template-card-2 .card-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
