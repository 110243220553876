.ant-table-container .ant-table {
  border-radius: 25px;
}

.ant-table-container .ant-table .ant-table-title {
  padding: 0;
}

.ant-tabs-container .ant-tabs-nav .ant-tabs-nav-wrap {
  display: block;
}

.ant-tabs-container .ant-tabs-nav-list {
  justify-content: space-evenly;
}

.cta-list .ant-card-body {
  padding: 0;
}

.template-radio-card .ant-card-body {
  padding: 24px 24px 24px 16px;
}

.cta-wizard .ant-form-item-explain-error {
  width: 89%;
}
